import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IFilterCard } from 'src/Interfaces/IFilterCard'
import { Skeleton } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { FilterCardEnum } from 'src/enums/FilterCard'
import { PageTitles } from 'src/enums/PageTitles'
import { PreSimulateEnum } from 'src/enums/PreSimulate'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { UrlActions } from 'src/store/ducks/aboutUrl/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { ICard } from 'src/store/ducks/hiring/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { H1, H3, PMedium } from 'src/styles/commons'
import { SIZE_MD, SIZE_SM } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'

import { Card } from './Card/Card'
import { Amount, Filters, Grid, NoCards } from './LettersCredit.styles'

export const LettersCredit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const account_context = useSelector(NewRelicActions.getUserContext)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const deeplinkFilter = useSelector(PathParamsActions.getDeepLinkCardsFilter)
  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)

  const selectedTab = useSelector(TabActions.get)
  const selectedValue = useSelector(HiringActions.getSelectedValue)
  const isLoading = useSelector(LoadingActions.get)
  const { productId, subProductId, product } = useSelector(HiringActions.getSelectedModality)
  const { mainTitle, mainSubtitle, cards } = useSelector(HiringActions.getCards)
  const [currentFilter, setCurrentFilter] = useState(deeplinkFilter || FilterCardEnum.MIN)

  const notFound = (
    <Icons.NotFound width={SIZE_MD} height={SIZE_MD} color={theme.colors.neutral.grayscale.A300} />
  )

  const iconEdit = (
    <Icons.Edit
      width={SIZE_SM}
      height={SIZE_SM}
      color={theme.colors.primary.A500}
      onClick={() => history.push(TypesRoutes.PRE_SIMULATE)}
      data-testid="test_buttonEdit"
    />
  )

  useEffect(() => {
    dispatch(UrlActions.knowMore())
  }, [])

  const handleBackButton = () => {
    if (accessViaDeepLink && !subProductId) {
      dispatch(PathParamsActions.setAccessedViaDeepLink(false))
      history.replace(TypesRoutes.START)
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: handleBackButton,
  })

  const handleClickHighlights = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        highlights: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const handleClickLowestPrice = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        lowestPrice: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const handleClickBiggestPrice = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        biggestPrice: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const onHandleClickedDetail = (card: ICard) => {
    BaseBridge.requestAnalytics(TAGS.CONSOR_FLUXO.screen, {
      ...TAGS.CONSOR_FLUXO,
      account_context,
      segment,
      ref_figma: '5',
      action_id: card.cardTitle,
      Modalidade: product,
      Oferta: `${card.offer}`,
      'Valor da carta': card.consortiumValue.toString(),
      'Valor da Parcela': card.installmentValue.toString(),
    })

    dispatch(
      HiringActions.getCheckoutRequest({
        history,
        pathname: TypesRoutes.CHECKOUT,
        cardId: card.cardId,
        productId,
        subProductId,
        group: card.group,
        installmentValue: card.installmentValue,
      }),
    )
  }

  const filters: IFilterCard[] = [
    { label: FilterCardEnum.MIN, action: handleClickLowestPrice },
    { label: FilterCardEnum.MAX, action: handleClickBiggestPrice },
    { label: FilterCardEnum.HIGHLIGHTS, action: handleClickHighlights },
  ]

  const renderCards =
    cards && cards.length !== 0 ? (
      <Grid>
        {cards?.map((card, index) => (
          <Card
            key={uuid()}
            cardDetails={card}
            onClickedDetail={() => onHandleClickedDetail(card)}
            data-testid={`card-${index}`}
          />
        ))}
      </Grid>
    ) : (
      <NoCards>
        {notFound}
        <H3 margin="24px 0 8px">Não encontramos opções com o valor desejado</H3>
        <PMedium scale={400} marginBottom="0">
          Busque um valor diferente e escolha a melhor opção para você.
        </PMedium>
      </NoCards>
    )

  const renderValues = (
    <Amount>
      <PMedium marginBottom="0" bold>
        {selectedTab === PreSimulateEnum.INSTALLMENT
          ? 'Parcelas a partir de '
          : 'Valor total a partir de '}
        {formatToBRL(selectedValue)}
      </PMedium>
      {!accessViaDeepLink && iconEdit}
    </Amount>
  )

  const renderFilters = (
    <>
      <Filters>
        {filters.map((filter, index) => (
          <InterUITag
            key={uuid()}
            id={filter.label}
            variant="action-check"
            type="radio"
            checked={filter.label === currentFilter}
            margin={index !== filters.length - 1 ? '0 8px 0 0' : '0'}
            onChange={() => filter.action(filter.label)}
            data-testid={`test_filter-${index}`}
          >
            {filter.label}
          </InterUITag>
        ))}
      </Filters>
    </>
  )

  return (
    <InterUIContainer margin="0px 24px 24px">
      <InterUIContainer margin="24px 0px">
        <H1 marginBottom="4px">{mainTitle}</H1>
        <PMedium marginBottom="24px" scale={400}>
          {mainSubtitle}
        </PMedium>

        {selectedValue > 0 ? renderValues : renderFilters}

        {isLoading ? <Skeleton.LoadLettersCredit /> : renderCards}
      </InterUIContainer>
    </InterUIContainer>
  )
}
