/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

/**
 * Formata um numero para moeda Real sem o prefix "R$"
 * @param data numero a ser formatado
 * @returns uma string com o valor formatado em Reais
 */
export const numberFormat = (data: number | string) => {
  try {
    const formatter = data.toLocaleString('pt-br', { minimumFractionDigits: 2 })

    return formatter
  } catch {
    return data.toString()
  }
}

/**
 * Obtém o tamanho do input de acordo com o texto informado.
 * @param text texto informado no input.
 * @returns pixel do width do input
 */
export const getInputSizeByText = (text: string): string => {
  let size = 0
  const value = text.split('')

  value.forEach((char) => {
    if (char.match(/[.,]/g)) {
      size += 9
    } else if (char.match(/[1]/g)) {
      size += 11
    } else if (char.match(/[7]/g)) {
      size += 15
    } else if (char.match(/[235]/g)) {
      size += 16
    } else if (char.match(/[4689]/g)) {
      size += 17
    } else if (char.match(/[0]/g)) {
      size += 18
    }
  })

  return `${size}px`
}

export const isStringNotEmpty = (str?: string) =>
  str !== undefined && str !== null && str !== '' && str.length > 0

/**
 * Formata uma determinada string no padrão yyyy-mm-dd para o padrão yyyy/mm/dd
 * @param str data no formato yyyy-mm-dd
 * @returns data no formato yyyy/mm/dd
 */
export const formatDateWithBar = (str: string) => {
  if (isStringNotEmpty(str)) {
    const dates = str.split('-')
    const day = dates[2]
    const month = dates[1]
    const year = dates[0]

    str = `${year}/${month}/${day}`
  }

  return str
}

/**
 * Formata uma determinada string no padrão yyyy-mm-dd para retornar somente o dia
 * @param str string no formato yyyy-mm-dd
 * @returns dia
 */
export const formatDateWithDay = (str: string) => {
  try {
    const date = new Date(formatDateWithBar(str))
    const day = date.getDate()

    return day
  } catch {
    return str
  }
}

/**
 * Formata uma date para o padrão dia de mês do ano
 * @param str date
 * @returns date no formato dia de mês do ano
 */
export const formatDayMonthYear = (date: Date) => {
  try {
    const localeDate = date.toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })

    return localeDate
  } catch {
    return date
  }
}

/**
 * Formata uma determinada string no padrão yyyy-mm-dd para retornar somente o dia e o mês
 * @param str string no formato yyyy-mm-dd
 * @returns dia/mês
 */
export const formatDateWithoutYear = (str: string) => {
  try {
    const date = new Date(str)
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
    })

    return formattedDate
  } catch {
    return str
  }
}

/**
 * Retorna o mês por extenso de uma data yy-mm-dd
 * @param dateString string
 * @returns nome do mês por extenso
 */
export const getMonthNameFromStringDate = (dateString: string) => {
  const date = new Date(dateString)
  const monthName = date.toLocaleString('pt-BR', { month: 'long' })
  return monthName[0].toUpperCase() + monthName.substring(1).toLowerCase()
}

/**
 * Retorna a data de um date string
 * @param dateString string
 * @returns a data no formato dd/mm/yyyy
 */
export const getDateFromStringDate = (dateString: string) => {
  const date = new Date(dateString)
  return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

/**
 * Retorna a conversão de uma string de versão para um número inteiro, separando
 * essas seções em subseções e colocando-as na posição decimal correta.
 * example: 10.1.2 -> 100102
 * @param appVersion string
 */
export const convertAppVersionToNumber = (appVersion: string) => {
  const applicationVersion = parseFloat(appVersion).toString().split('.')

  let multiplyFactor = 10000
  let result = 0

  applicationVersion.forEach((section) => {
    const sectionValue = parseInt(section, 10) * multiplyFactor
    multiplyFactor /= 100
    result += sectionValue
  })

  return result
}
