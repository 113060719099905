import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { ValidationActions } from 'src/store/ducks/validation/actions'
import { VideoUrlActions } from 'src/store/ducks/videoUrl/actions'
import { ContainerFullPage } from 'src/styles/commons'

export const Start: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useSelector(LoadingActions.get)

  const handlerQueryParams = PathQueryParams()

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    handlerQueryParams()
  }, [])

  useEffect(() => {
    dispatch(
      ValidationActions.getEligibleRequest({
        onTransmission: redirectPage,
      }),
    )
  }, [])

  const redirectPage = () => {
    const accessViaBannerOrDeepLink = handlerQueryParams()

    if (!accessViaBannerOrDeepLink) {
      dispatch(VideoUrlActions.getVideoUrlRequest())
      dispatch(HiringActions.getHomeRequest({ history, pathname: TypesRoutes.HOME }))
    }
  }

  return (
    <ContainerFullPage height="0px" overflowHidden>
      {isLoading && <Skeleton.LoadHome />}
    </ContainerFullPage>
  )
}
