import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { CheckoutEnum, walletClientIdEnum } from 'src/enums/Checkout'
import { formPaymentEmpty } from 'src/mocks/Checkout'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PMedium, PSmall } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIListDescription } from '@interco/inter-ui-react-lib'

import { ContentPayment } from './PaymentMethod.style'

interface PaymentMethodProps {
  type: CheckoutEnum
  amount: number
  proposalUuid: string
  salesPlanId?: number
  salesType?: number
  inInsurance?: boolean
  installmentNumber?: string
}

export const PaymentMethod = ({
  type,
  amount,
  proposalUuid,
  salesPlanId,
  salesType,
  inInsurance,
  installmentNumber,
}: PaymentMethodProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const appVersion = useSelector(NewRelicActions.getUserAppVersion)

  const grayscale200 = theme.colors.neutral.grayscale.A200
  const grayscale500 = theme.colors.neutral.grayscale.A500
  const primary = theme.colors.primary.A500

  const walletIcons = {
    payment: <Icons.Dollar width={SIZE_MD} height={SIZE_MD} color={grayscale500} />,
    edit: <Icons.Edit width={SIZE_MD} height={SIZE_MD} color={primary} />,
    debit: <Icons.Wallet width={SIZE_MD} height={SIZE_MD} color={primary} />,
    credit: <Icons.Card width={SIZE_MD} height={SIZE_MD} color={primary} />,
    right: <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={primary} />,
  }

  const formOfPaymentIcon = (iconWallet: string) => {
    let icon: React.ReactElement

    switch (iconWallet) {
      case 'DEBIT':
        icon = walletIcons.debit
        break

      case 'CREDIT':
        icon = walletIcons.credit
        break

      default:
        icon = walletIcons.payment
        break
    }

    return icon
  }

  useEffect(() => {
    if (appVersion.startsWith('13.3') || appVersion.startsWith('13.03')) {
      if (amount !== 0) {
        getPaymentMethod()
      }
    }
  }, [amount])

  let additionalFields = {}

  if (type === CheckoutEnum.HIRE) {
    additionalFields = {
      proposalUuid,
      salesPlanId: salesPlanId?.toString(),
      salesType: salesType?.toString(),
      inInsurance: inInsurance?.toString(),
    }
  } else {
    additionalFields = {
      proposalUuid,
      installmentNumber,
    }
  }

  const additionalQueryRequestParams = additionalFields

  const getPaymentMethod = async () => {
    try {
      const response = await CheckoutBaseService.paymentDefault(
        `${amount}`,
        walletClientIdEnum.CONSORTIUM,
        additionalFields,
        additionalQueryRequestParams,
      )

      if (response) {
        dispatch(
          CheckoutActions.setFormOfPayment({
            title: response.title || formPaymentEmpty.title,
            subTitle: response.subTitle,
            type: response.type,
          }),
        )
      }
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Hiring.paymentDefault',
      })

      dispatch(
        CheckoutActions.setFormOfPayment({
          title: formPaymentEmpty.title,
          subTitle: formPaymentEmpty.subTitle,
          type: formPaymentEmpty.type,
        }),
      )
    }
  }

  const openChoicePaymentForm = async () => {
    try {
      const response = await CheckoutBaseService.openWallet(
        `${amount}`,
        walletClientIdEnum.CONSORTIUM,
        additionalFields,
        additionalQueryRequestParams,
      )

      dispatch(CheckoutActions.setFormOfPayment(response))
    } catch (error) {
      const errorDetails = {
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        disabledButton: false,
        route: TypesRoutes.START,
      }

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Hiring.openWallet',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  return (
    <ContentPayment className={amount <= 0 ? 'disabled' : ''}>
      <InterUIListDescription
        iconLeft={formOfPaymentIcon(formOfPayment.type)}
        iconRight={walletIcons.right}
        withBorder
        alignAllItems="center"
        borderColor={formOfPayment.type ? primary : grayscale200}
        onClick={openChoicePaymentForm}
        data-testid="payment-methods"
      >
        <PMedium marginBottom={formOfPayment.subTitle !== '' ? '8px' : '0px'} bold>
          {formOfPayment.title}
        </PMedium>
        {formOfPayment.subTitle !== '' && (
          <PSmall marginBottom="0px">{formOfPayment.subTitle}</PSmall>
        )}
      </InterUIListDescription>
    </ContentPayment>
  )
}
